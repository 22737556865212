import axios from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import { attach } from 'retry-axios';

const instance = axios.create({
  baseURL: `${process.env.CHECKOUT_HOST}/widgets/api/`,
  timeout: 3000,
  withCredentials: false,
  raxConfig: {
    backoffType: 'exponential',
    retry: 3,
    noResponseRetries: 2,
    httpMethodsToRetry: ['GET', 'HEAD', 'OPTIONS', 'DELETE', 'PUT', 'PATCH'],
    statusCodesToRetry: [
      [100, 199],
      [429, 429],
      [500, 599],
      [409, 409], // transactions conflict
    ],
  },
});

export const axiosInstance = applyCaseMiddleware(instance, {
  ignoreHeaders: true,
});

attach(axiosInstance);
