import { datadogRum } from '@datadog/browser-rum';
import { MOBILE_BRIDGE_QUERY_PARAM_NAME } from 'src/constants/common';

export const MOBILE_BRIDGE_ACTIONS = {
  OPEN_URL: 'openUrl',
  INITIALIZATION_DATA: 'initializationData',
} as const;

type MobileBridgePayload = {
  type: typeof MOBILE_BRIDGE_ACTIONS[keyof typeof MOBILE_BRIDGE_ACTIONS];
  data: {
    url: string;
    [key: string]: unknown;
  };
};

const AWAITING_TIMEOUT = 500;

export class MobileBridge {
  private readonly mobileBridgeBus =
    window?.webkit?.messageHandlers?.tabbyMobileSDK || window?.tabbyMobileSDK;

  private timeoutTimerID: ReturnType<typeof setTimeout> | undefined;

  public sendMessage(payload: MobileBridgePayload) {
    try {
      const serializedPayload = JSON.stringify(payload);

      this.mobileBridgeBus?.postMessage(serializedPayload);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('MobileBridge serialize error', error);

      datadogRum.addError(error);
    }
  }

  public openUrl(url: string, data: Record<string, unknown> = {}) {
    const markedUrl = this.markUrl(url);

    this.sendMessage({
      type: MOBILE_BRIDGE_ACTIONS.OPEN_URL,
      data: { url: markedUrl.toString(), ...data },
    });
  }

  public awaitData() {
    const dataAwaiter = new Promise<Record<string, unknown>>(
      (resolve, reject) => {
        window.addEventListener('load', () => {
          this.timeoutTimerID = setTimeout(() => {
            reject(new Error('timeout'));
          }, AWAITING_TIMEOUT);
        });

        window.addEventListener('message', (event) => {
          const { parsedData } = this.parseData(event?.data);

          if (parsedData?.type === MOBILE_BRIDGE_ACTIONS.INITIALIZATION_DATA) {
            resolve(parsedData.data);

            clearTimeout(this.timeoutTimerID);
          }
        });
      }
    );

    return dataAwaiter;
  }

  private markUrl(url: string) {
    const newUrl = new URL(url);

    newUrl.searchParams.append(MOBILE_BRIDGE_QUERY_PARAM_NAME, '1');

    return newUrl.toString();
  }

  private parseData(data: string) {
    if (typeof data !== 'string') {
      return {};
    }

    try {
      const parsedData = JSON.parse(data);

      return { parsedData };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('MobileBridge parse data error', error);

      datadogRum.addError(error);

      return {};
    }
  }
}

export const mobileBridge = new MobileBridge();
