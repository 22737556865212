export const createNormalizeCheckingNumber =
  (descriptionError: string, defaultValue: number) => (value: any) => {
    if (value === undefined || value === null || value === '') {
      return defaultValue;
    }

    if (typeof value === 'string') {
      const num = (value as string).replace(/,/g, '.');

      if (Number.isFinite(Number(num)) && Number(num) >= 0) {
        return Number(num);
      }

      if (Number.isNaN(Number(num))) {
        // eslint-disable-next-line no-console
        console.error(descriptionError);

        return defaultValue;
      }
    }

    if (typeof value === 'number' && Number.isFinite(value) && value >= 0) {
      return value;
    }

    return defaultValue;
  };
