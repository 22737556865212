import {
  allowedCurrencies,
  AllowedCurrency,
  Currency,
} from 'src/types/currency';
import { createNormalizeChecking } from 'src/utils/create-normalize-checking';

export const checkCurrency = createNormalizeChecking(
  (v) => `${v}`.trim().toUpperCase() as Currency,
  (v) => v in Currency,
  'The currency parameter has an incorrect value. Please check it',
  Currency.AED
);

export const formatCurrency = createNormalizeChecking<
  AllowedCurrency,
  undefined
>(
  (v) => `${v}`.trim().toUpperCase() as AllowedCurrency,
  (v): v is AllowedCurrency => allowedCurrencies.includes(v as any),
  'The currency parameter has an incorrect value. Please check it',
  undefined
);
