export const Currency = {
  AED: 'AED',
  KWD: 'KWD',
  SAR: 'SAR',
  BHD: 'BHD',
  QAR: 'QAR',
} as const;

export type Currency = typeof Currency[keyof typeof Currency];

export const allowedCurrencies = [
  Currency.AED,
  Currency.SAR,
  Currency.KWD,
] as const;

export type AllowedCurrency = typeof allowedCurrencies[number];
