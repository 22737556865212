export const WIDGET_INITIALIZED = 'Widget Initialized';

export const ACTIONS = {
  UPDATE_STORE: 'updateStore',
  PREPARE_ALSHAYA_LIMIT: 'prepareAlshayaLimit',
  UPDATE_FROM_DATASET: 'updateFromDataSet',
  SET_TITLES: 'setTitles',
  PREPARE_PAY_IN_FULL_POPUP: 'preparePayInFullPopup',
  PREPARE_SCHEDULE_POPUP: 'prepareSchedulePopup',
  GET_WIDGET_DATA: 'getWidgetData',
} as const;

export type ACTIONS = typeof ACTIONS[keyof typeof ACTIONS];
export const MOBILE_BRIDGE_QUERY_PARAM_NAME = 'mobileBridge';
