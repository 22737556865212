import { WebRequest, WebResponse } from 'src/types/widget-data';
import { axiosInstance } from 'src/utils/axios';

const REQUEST_TIMEOUT = 2000;

export const getWidgetData = async (body: WebRequest): Promise<WebResponse> => {
  const controller = new AbortController();
  const { signal } = controller;

  setTimeout(() => {
    controller.abort();
  }, REQUEST_TIMEOUT);

  try {
    const { data } = await axiosInstance.post('/web', body, { signal });

    return data;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('POST /web failed', e);

    return {
      experiments: {
        experiments: [],
        entityId: '',
      },
      aid: '',
      offers: { installments: [] },
    };
  }
};
