import { datadogRum } from '@datadog/browser-rum';
import { useCallback, useEffect } from 'react';
import { getWidgetData } from 'src/api/get-widget-data';
import { getEntityId } from 'src/checkout-frame/api/get-entity-id';
import { ACTIONS, MOBILE_BRIDGE_QUERY_PARAM_NAME } from 'src/constants/common';
import { Actions } from 'src/context/store/store-reducer';
import { mobileBridge } from 'src/services/mobile-bridge';
import { ExperimentParams } from 'src/types/experiment';

const clearPayloadFromEmptyValues = <T>(rest) => {
  return Object.entries(rest).reduce<T>((acc, [key, value]) => {
    if (value !== '') {
      return {
        ...acc,
        [key]: value,
      };
    }

    return acc;
  }, {} as T);
};

export const useWidgetData = ({
  dispatch,
  ...rest
}: ExperimentParams & {
  amount?: number;
  webview?: boolean;
  dispatch: React.Dispatch<Actions>;
  loading: boolean;
}) => {
  const { publicKey, merchantCode, currency, amount, webview } =
    clearPayloadFromEmptyValues<typeof rest>(rest);

  const getData = useCallback(async () => {
    const shouldGetDataFromMobileBridge = window.location.search.includes(
      MOBILE_BRIDGE_QUERY_PARAM_NAME
    );

    if (shouldGetDataFromMobileBridge) {
      try {
        const { url, ...data } = await mobileBridge.awaitData();

        dispatch({ type: ACTIONS.GET_WIDGET_DATA, payload: data });

        return;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('mobileBridge.awaitData error', e);
        datadogRum.addError(e);
      }
    }

    const entityId = await getEntityId();

    const data = await getWidgetData({
      ab: {
        entityId,
        entity: 'widgets_customer',
        platform: 'web',
        filters: {
          merchantId: publicKey,
          merchantCode,
          currency,
        },
      },
      publicKey,
      merchantCode,
      currency,
      amount,
      webview,
    });

    dispatch({
      type: ACTIONS.GET_WIDGET_DATA,
      payload: data,
    });
  }, [publicKey, merchantCode, currency, amount, dispatch, webview]);

  useEffect(() => {
    getData();
  }, [currency, amount, getData]);
};
