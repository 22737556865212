import { Lang, LangDictionary, Locale } from 'src/types/lang-locale-type';

export const dictionaryMapLanguage: {
  [key in LangDictionary]: {
    lang: Lang;
    locale: Locale;
  };
} = {
  [LangDictionary.en]: {
    lang: Lang.en,
    locale: Locale['en-US'],
  },
  [LangDictionary.ar]: {
    lang: Lang.ar,
    locale: Locale['ar-AE'],
  },
};
